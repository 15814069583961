<template>
    <div>
        <ValidationObserver ref="form1" slim>
            <b-container class="mt-5 mb-5">
                <h1 class="fs-4 font-weight-bold mb-4">Gast toevoegen: {{ event.name }}</h1>
                
                <b-card class="mb-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="mb-3">
                            <label class="required">Datum</label>
                            <b-form-select v-model="form.event_date_id" :options="eventDateOptions" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                    </ValidationProvider>
                </b-card>

                <b-card v-for="(guest, key) in form.guests" :key="key" class="mb-3" no-body>
                    <b-card-header class="text-right" v-if="key > 0">
                        <b-button variant="outline-danger" @click="removeGuest(guest)" size="sm">
                            <font-awesome-icon icon="remove" />
                        </b-button>
                    </b-card-header>
                    <b-card-body>
                        <b-row cols="2" cols-md="3" cols-lg="5">
                            <b-col>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Voornaam</label>
                                        <b-form-input v-model="guest.firstname" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Lastname</label>
                                        <b-form-input v-model="guest.lastname" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required|email" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">E-mailadres</label>
                                        <b-form-input v-model="guest.email" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required|numeric" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Aantal</label>
                                        <b-form-input v-model="guest.amount" v-mask="'#########'" placeholder="1" min="1" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Ticket</label>
                                        <b-form-select v-model="guest.event_ticket_id" :options="ticketOptions" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <div class="text-center">
                    <b-button variant="outline-primary" @click="addGuest">
                        Gast toevoegen
                        <font-awesome-icon icon="plus" />
                    </b-button>
                </div>

                <hr/>

                <div class="text-right">
                    <p class="text-muted">Na het toevoegen worden de tickets automatisch verzonden naar de ingevulde e-mailadressen.</p>
                    <b-button variant="primary" @click="saveGuests">Toevoegen</b-button>
                </div>
                

            </b-container>
        </ValidationObserver>
    </div>
</template>
  
<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        metaInfo: {
            title: 'Gasten',
        },
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        data() {
            return {
                event: {},
                dates: [],
                tickets: [],
                form: {
                    event_date_id: null,
                    guests: [],
                }
            }
        },
        computed: {
            ticketOptions() {
                let options = [];

                options.push({ text: 'Selecteer een optie', value: null });

                this.tickets.forEach( item => {
                    options.push({ text: item.name, value: item.id });
                });
                
                return options;
            },
            eventDateOptions() {
                let options = [];

                options.push({ text: 'Selecteer een optie', value: null });

                this.dates.forEach( item => {
                    options.push({ text: item.start_at, value: item.id });
                });
                
                return options;
            }
        },
        methods: {
            ...mapActions(useShopStore, ['setLoading']),
            fetchEvent: function() {
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id)
                    .then( response => {
                        if (response.data) {
                            this.event = response.data;
                            this.setLoading(false);
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            addGuest: function() {
                let item = {
                    amount: 1,
                    event_ticket_id: null,
                };
                this.form.guests.push(item);
            },
            removeGuest: function(guest) {
                var index = this.form.guests.indexOf(guest);
                if (index !== -1) {
                    this.form.guests.splice(index, 1);
                }
            },
            fetchEventDates: function() {
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id + '/dates')
                    .then( response => {
                        this.dates = response.data;
                        this.setLoading(false);
                    })
                ;
            },
            fetchEventTickets: function() {
                this.setLoading(true);
                this.$axios.get('https://api.tixgo.nl/events/' + this.$route.params.id + '/tickets')
                    .then( response => {
                        this.tickets = response.data;
                        this.setLoading(false);
                    })
                ;
            },
            async saveGuests() {
                let check = await this.$refs['form1'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    return;
                }

                this.setLoading(true);
                this.$axios.post('https://api.tixgo.nl/events/' + this.$route.params.id + '/guests', this.form)
                    .then( response => {
                        response;
                        this.setLoading(false);
                        this.$router.push('/manage/events/' + this.$route.params.id + '/guests');
                });
            }
        },
        created() {
            this.fetchEvent();
            this.fetchEventDates();
            this.fetchEventTickets();
            this.addGuest();
        },
    }
</script>